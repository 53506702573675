const app = {
  init: function () {
    this.initNav();
    this.initVideos();
  },

  initNav: function () {
    const htmlEl = document.querySelector("html");
    const bodyEl = document.querySelector("body");
    const btnMobileNav = document.querySelector("#btn-mobile-nav");

    if (btnMobileNav) {
      btnMobileNav.addEventListener("click", function () {
        if (htmlEl) {
          htmlEl.classList.toggle("nav-is-open");
        }

        if (bodyEl) {
          bodyEl.classList.toggle("nav-is-open");
        }

        btnMobileNav.innerText = htmlEl.classList.contains("nav-is-open")
          ? "Stäng"
          : "Meny";
      });
    }
  },

  initVideos: function () {
    fluidvids.init({
      selector: ["iframe", "object"], // runs querySelectorAll()
      players: ["www.youtube.com", "player.vimeo.com"], // players to support
    });

    // look for wp block cover with video
    const heroContainer = document.querySelector(".wp-block-cover");
    const heroVideo = document.querySelector(
      ".wp-block-cover__video-background"
    );

    // hero autoplay check
    if (heroVideo) {
      heroVideo.pause();
      heroVideo.play();

      // check if phone is in power saving mode
      heroVideo.addEventListener("suspend", () => {
        heroVideo.play();

        if (heroVideo.paused) {
          heroVideo.classList.add("video-disabled");

          if (heroContainer) {
            heroContainer.classList.add("container-video-disabled");
          }
        }
      });
    }
  },
};
app.init();
